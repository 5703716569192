(function ($) {
  'use strict';

  Drupal.behaviors.shadePickerV1 = {
    isCustomPalette: $('.page--spp__custom_palette').length > 0 ? true : false,
    initShadeTools: function ($container) {
      var self = this;
      var tools = $container.find('.shade-picker__tools');
      // sorting
      var sortToggle = tools.find('.shade-picker__tools-sort-toggle');
      var sortItems = tools.find('.shade-picker__tools-sort-items');

      sortToggle.once().click(function () {
        var $this = $(this);

        if (sortItems.hasClass('hidden')) {
          sortItems.hide().removeClass('hidden').slideDown(300);
          $this.addClass('active');
        } else {
          sortItems.slideUp(300, function () {
            sortItems.addClass('hidden');
            $this.removeClass('active');
          });
        }

        // @todo sort shades based on selection
      });

      // main filtering
      var filterToggle = tools.find('.shade-picker__tools-filters-toggle');
      var clearFilters = tools.find('.shade-picker__tools-filters-clear');
      var mainFilters = tools.find('.shade-picker__tools-filters');
      var subFilterToggle = mainFilters.find('.shade-picker__tools-title');
      var subFilterItems = mainFilters.find('.shade-picker__tools-filters-items');
      var filterItem = subFilterItems.find('a');
      var $shades = self.isCustomPalette ? $('.shade-picker__colors-insert[data-insert="' + tools.data('insert') + '"]').find('.shade-picker__colors-item') : $('.content .site-container .js-shade-picker--v1').find('.shade-picker__color');

      filterToggle.once().click(function () {
        if (mainFilters.hasClass('hidden')) {
          filterToggle.addClass('active');
          subFilterItems.addClass('hidden');
          mainFilters.hide().removeClass('hidden').slideDown(300);
        } else {
          filterToggle.removeClass('active');
          subFilterToggle.removeClass('active');
          mainFilters.show().slideUp(300);
          setTimeout(function () {
            mainFilters.addClass('hidden');
          }, 300);
        }
      });

      // sub filtering

      subFilterToggle.once().click(function () {
        var $this = $(this);
        var thisSubFilterItems = $this.siblings('.shade-picker__tools-filters-items');

        if (thisSubFilterItems.hasClass('hidden')) {
          $(this).addClass('active');
          thisSubFilterItems.hide().removeClass('hidden').slideDown(300);
          subFilterItems.not(thisSubFilterItems).slideUp(300, function () {
            subFilterItems.not(thisSubFilterItems).addClass('hidden');
            subFilterToggle.not($this).removeClass('active');
          });
        } else {
          subFilterItems.slideUp(300, function () {
            $(this).addClass('hidden');
            subFilterToggle.removeClass('active');
          });
        }
      });

      // filter shades based on selections
      var shadesMap = [];

      $shades.each(function () {
        var filters = $(this).data('filters');
        var sku = self.isCustomPalette ? $(this).find('.shade-picker__color').data('product') : $(this).data('product');
        var combined = $.extend({}, sku, filters);

        shadesMap.push(combined);
      });

      var facetsMap = [];

      filterItem.each(function () {
        var filter = $(this).data('filter');

        if (_.isObject(filter)) {
          facetsMap.push(filter);
        }
      });

      var filteredShades = [];

      filterItem.once().click(function () {
        var $this = $(this);
        var filtersCounter = $(this).parents('.shade-picker__tools-filter').find('.shade-picker__tools-title--count');
        var thisFacet = $this.data('facet'), thisFacetLength = 0;

        if (subFilterItems.find('.active').length === 1 && $this.hasClass('active')) {
          $this.removeClass('active');
          filtersCounter.html('');
          clearFilters.addClass('hidden');
          filterToggle.removeClass('hidden');
          $shades.removeClass('hidden');
        } else {
          $this.toggleClass('active');
          clearFilters.removeClass('hidden');
          filterToggle.addClass('hidden');
          var selectedFacets = subFilterItems.find('.active');
          var filters = [];

          selectedFacets.each(function () {
            var filterBy = $(this).data('filter');

            filters.push(filterBy);
          });

          filteredShades = [];
          $(filters).each(function () {
            var thisFilter = $(this)[0];
            var facet = _.keys(thisFilter);

            _.filter(shadesMap, function (shade) {
              var shadeFilterValue = shade[facet].toUpperCase().replace(' ', '-');
              var filterByValue = thisFilter[facet].toUpperCase();

              if (shadeFilterValue === filterByValue) {
                filteredShades.push(shade);
                if (thisFacet == facet[0]) {
                  thisFacetLength++;
                }
              }
            });
          });

          clearFilters.hide().removeClass('hidden').slideDown(300);
          filtersCounter.html(thisFacetLength != 0 ? '(' + thisFacetLength + ')' : '');
          self.filterShades($shades, filteredShades);
        }

        return false;
      });

      clearFilters.once().click(function () {
        filterToggle.removeClass('hidden');
        subFilterToggle.removeClass('active');
        clearFilters.addClass('hidden');
        mainFilters.slideUp(300, function () {
          mainFilters.addClass('hidden');
          $('.shade-picker__tools-title--count').html('');
        });

        // reset all shades
        filterItem.removeClass('active');
        $shades.removeClass('hidden');
      });
    },

    filterShades: function ($shades, filteredShades) {
      $shades.addClass('hidden');
      $(filteredShades).each(function () {
        var thisFilter = $(this)[0];

        $shades.each(function () {
          var thisShade = self.isCustomPalette ? $(this).find('.shade-picker__color') : $(this);
          var data = thisShade.data('product');

          if (data.SKU_BASE_ID === thisFilter.SKU_BASE_ID) {
            if (self.isCustomPalette) {
              thisShade.parents('.shade-picker__colors-item').removeClass('hidden');
            } else {
              thisShade.removeClass('hidden');
            }
          }
        });
      });
    },

    attach: function (context, settings) {
      var self = this;
      var $container = $('.js-shade-picker--v1', context);

      // init shade filters / sorting
      self.initShadeTools($container);

      var _swapImage = function ($element) {
        var $sib = $element.siblings('.shade-picker__color-texture');

        if ($sib.length) {
          var img = $sib.data('bg-image');

          if (img && img.length > 0) {
            $sib.css('background-image', 'url(' + img + ')');
          }
        }
      };

      // Hack to lazy-load background images for smooshes
      $('.shade-picker__color-chip', $container)
        .once('shade-picker__color-chip')
        .hover(
          function () {
            _swapImage($(this));
          },
          function () {
            // do nothing on purpose
          }
        )
        .first()
        .map(
          function () {
            _swapImage($(this));
          }
        );
    }
  };
})(jQuery);
